ul {
  list-style-type: none;
}

.title {
  margin-bottom: 20px;
}

.title h1 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  color: #16141A;
}

.title p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 30px;
  color: #16141A;
}

.subtitle {
  margin-bottom: 20px;
}

.subtitle h2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 30px;
  color: #16141A;
}

.subtitle p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #736E7D;
}

.history-button {
  color: #4183c4;
  text-decoration: underline;
}

.page-container a {
  color: #4183c4;
}

.page-container a:hover {
  text-decoration: underline;
}

.catalysts-network::before {
  content: ' ';
  width: 12px;
  height: 9px;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  margin-right: 8px;
  filter: var(--brightness);
  background-image: url('./assets/arrow-down.svg');
  transition: transform 0.25s ease;
}

.catalysts-network.caret-down::before {
  transform: rotateZ(0deg) translateY(-1px);
}

.catalysts-network.caret-side::before {
  transform: rotateZ(-90deg) translateY(1px);
}

.health-info {
  float: right;
}

.health-info.operational {
  color: rgb(61, 167, 81);
}

.health-info.degraded {
  color: rgb(255, 222, 22);
}

.health-info.unavailable {
  color: rgb(255, 56, 56);
}

.health-row {
  padding-top: 10px;
  padding-bottom: 15px;
  font-weight: 600;
}

.health-row div { 
  display: inline;
  padding: 5px;
}

.health-row::before {
  content: ' ';
  position: relative;
  top: 4px;
  width: 19px;
  height: 19px;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  margin-right: 8px;
  transition: transform 0.25s ease;
}

.health-row.operational::before {
  filter: brightness(0) saturate(100%) invert(55%) sepia(11%) saturate(2697%) hue-rotate(79deg) brightness(99%) contrast(72%);;
  background-image: url('./assets/circle-check.svg');
}

.health-row.degraded::before {
  filter: brightness(0) saturate(100%) invert(88%) sepia(28%) saturate(5597%) hue-rotate(360deg) brightness(103%) contrast(107%);
  background-image: url('./assets/circle-exclamation.svg');
}

.health-row.unavailable::before {
  filter: brightness(0) saturate(100%) invert(51%) sepia(96%) saturate(5466%) hue-rotate(347deg) brightness(91%) contrast(85%);
  background-image: url('./assets/circle-xmark.svg');
}

.health-row .collapse > :first-child {
  padding-top: 25px;
}

.health-row .collapse > :last-child {
  padding-bottom: 0px;
}

.page-container > * {
  margin-bottom: 20px;
}
